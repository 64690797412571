<script lang="ts" setup>
  import { ref } from "vue"
  import { useRoute, useRouter } from "vue-router"

  import { getDemographicReportData } from "@/services/Api"
  import { getSiteByCmiId } from "@/services/Site"
  import { getProjectByCmiId } from "@/services/Project"
  import { setCurrentViewContext } from "@/utils/GlobalState"

  import PieChart from "@/components/PieChart.vue"
  import PageLoading from "@/components/PageLoading.vue"
  import ReportMenu from "@/components/assessments/ReportMenu.vue"

  const pageReady = ref(false)
  const data = ref(null)
  const route = useRoute()
  const router = useRouter()
  const siteCmiId = parseInt(route.params.siteCmiId as string)
  const projectCmiId = parseInt(route.params.projectCmiId as string)

  async function getData() {
    data.value = await getDemographicReportData({ siteCmiId, projectCmiId })
      .catch(error => {
        // FORBIDDEN is definitely a permissions issue; NOT_FOUND could well be one as well
        // (url fishing). Just redirect them silently.
        if (error.name === "NOT_FOUND" || error.name === "FORBIDDEN") {
          router.push({ name: "HomeRedirect" })
        }
        else {
          throw error
        }
      })
    pageReady.value = true

    if (siteCmiId) {
      const site = await getSiteByCmiId(siteCmiId)
      if (site) {
        setCurrentViewContext(site, "site")
      }
    }
    else if (projectCmiId) {
      const project = await getProjectByCmiId(projectCmiId)
      if (project) {
        setCurrentViewContext(project, "project")
      }
    }
  }

  getData()
</script>
<template>
  <article>
    <div class="columns">
      <div class="column">
        <div
          v-if="siteCmiId"
          class="super-title"
        >
          {{ $gettext("Site Reports") }}
        </div>
        <div
          v-else-if="projectCmiId"
          class="super-title"
        >
          {{ $gettext("Project Reports") }}
        </div>
        <div
          v-else
          class="super-title"
        >
          App-level Reports
        </div>
        <h1 class="title">
          {{ $gettext("Demographic Report") }}
        </h1>
      </div>
      <div class="column has-text-right">
        <ReportMenu
          selected="DemographicReport"
          :params="route.params"
        />
      </div>
    </div>
    <PageLoading v-if="!pageReady" />
    <template v-else>
      <section class="section block">
        <div class="columns is-mobile is-multiline">
          <div class="column is-half-mobile">
            <figure class="number-callout">
              <strong class="child_count">
                {{ data.totalChildrenCount.toLocaleString() }}
              </strong>
              <figcaption>
                {{ $gettext("Children") }}
                <div class="caption-footnote">
                {{ $gettext("All children who have been assessed at least once") }}
                </div>
              </figcaption>
            </figure>
          </div>
          <div class="column is-half-mobile">
            <figure class="number-callout">
              <strong class="child_count">
                {{ data.currentChildrenCount.toLocaleString() }}
              </strong>
              <figcaption>
                {{ $gettext("Current Children") }}
                <div class="caption-footnote">
                {{ $gettext("Children in active sites who have not been discharged") }}
                </div>
              </figcaption>
            </figure>
          </div>
          <div
            v-if="data.totalSiteCount"
            class="column is-half-mobile"
          >
            <figure class="number-callout">
              <strong class="child_count">
                {{ data.totalSiteCount }}
              </strong>
              <figcaption>
                {{ $gettext("Sites") }}
                <div
                  v-if="data.totalProjectCount"
                  class="caption-footnote"
                >
                {{ $gettext("%{number} active sites", { number: data.currentSiteCount }) }}
                </div>
              </figcaption>
            </figure>
          </div>
          <div
            v-if="data.totalProjectCount"
            class="column"
          >
            <figure class="number-callout">
              <strong class="child_count">
                {{ data.totalProjectCount }}
              </strong>
              <figcaption>
                Projects
                <div class="caption-footnote">
                  {{ data.currentProjectCount }} active projects
                </div>
              </figcaption>
            </figure>
          </div>
          <div
            v-else-if="data.totalSiteCount"
            class="column"
          >
            <figure class="number-callout">
              <strong class="child_count">
                {{ data.currentSiteCount }}
              </strong>
              <figcaption>
                {{ $gettext("Active Sites") }}
              </figcaption>
            </figure>
          </div>
        </div>
      </section>

      <section class="section block">
        <div class="columns">
          <div class="column">
            <PieChart
              :title="$gettext('Sex')"
              :data="data.sexBreakdown"
            />
          </div>
          <div class="column">
            <PieChart
              :title="$gettext('Type of care')"
              :data="data.serviceTypeBreakdown"
            />
          </div>
        </div>
      </section>

      <section class="section block">
        <div class="columns">
          <div class="column">
            <PieChart
              :title="$gettext('Children with special needs')"
              :data="data.withSpecialHealthcareBreakdown"
            />
          </div>
          <div class="column">
            <table class="table diagnoses">
              <caption>
                {{ $gettext("Details") }}
              </caption>
              <thead>
                <tr>
                  <th>
                    {{ $gettext("Diagnosis or Healthcare Need") }}
                  </th>
                  <th>
                    {{ $gettext("Children") }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="item in data.diagnosisBreakdown"
                  :key="item.label"
                >
                  <th>
                    {{ item.label }}
                  </th>
                  <td>
                    <div class="pct">
                      {{ item.pct }}
                    </div>
                    <span class="muted">
                      {{ $gettext("%{numerator} out of %{denominator}", { numerator: item.num.toLocaleString(), denominator: item.den.toLocaleString() }) }}
                    </span>
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td colspan="2">
                    {{ $gettext("Note that some children may have more than one healthcare need.") }}
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </section>

      <section class="section block">
        <div class="columns">
          <div class="column">
            <PieChart
              title="Reasons for becoming inactive"
              :data="data.dischargeReasonBreakdown"
            />
          </div>
          <div class="column">
            <PieChart
              title="Age at first assessment"
              :data="data.ageAtFirstAssessmentBreakdown"
            />
          </div>
        </div>
      </section>
    </template>
  </article>
</template>
<style lang="scss" scoped>
  .table.diagnoses {
    border: 1px solid rgb(128, 128, 128);
    border-radius: 10px;
    border-collapse: separate;
    width: 100%;
    thead th {
      text-align: center;
    }
    tbody {
      th {
        text-align: right;
      }
      td {
        text-align: center;
        line-height: 1.1;
      }
    }
    tfoot td {
      text-align: center;
      font-size: 85%;
      color: hsl(221, 14%, 71%);
    }
  }
</style>
